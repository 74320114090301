import '../../App.css'
import Intro from '../Home/Intro.js';
import Cards from '../Home/Cards.js'
import Information from '../Home/Information.js'

import React from 'react'

function Home(props) {
    return (
        <>
            <Intro />
            <Cards />
            <Information />
        </>
    )
}


export default Home

