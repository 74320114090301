import firebase from 'firebase/app';
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBphiRoKd8WOvR8IcxeHjBDs0S82iPVdgM",
  authDomain: "projetointegra-5e4cf.firebaseapp.com",
  databaseURL: "https://projetointegra-5e4cf.firebaseio.com",
  projectId: "projetointegra-5e4cf",
  storageBucket: "projetointegra-5e4cf.appspot.com",
  messagingSenderId: "769960932692",
  appId: "1:769960932692:web:947c9f0a9975bafb0e84c1",
  measurementId: "G-WZ6QE6ZNVB"
};

export default firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
console.log(firebase)
export { db }