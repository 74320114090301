import '../../App.css'
import Ideias_component from '../Lista_de_ideias/Ideias_component'
import Cadastro_ideias from '../cadastro/Cadastro_ideias'

import React from 'react'

function Ideias(props) {
    return (
        <>
            <Ideias_component />
            <Cadastro_ideias />
        </>
    )
}


export default Ideias

