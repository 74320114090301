import React, {useState} from 'react'
import '../Lista_de_projetos/Card.css'
import '../form_modified.css'
import firebase from '../../server.js'
import { db } from '../../server.js'
import ls from 'local-storage'

function Confirmar() {

    const [project_idd, setProject_idd] = React.useState('')
    const [recado, setRecado] = React.useState('')

    const project_id = (event) => {
        setProject_idd(event.target.value);
    }

    

    const confirmar_mail = async () => {
        console.log('banana')
            
            var docRef = db.collection("cards").doc(`ID: ${project_idd}`);
            var docIdeaRef = db.collection('ideias').doc(`ID: ${project_idd}`)

            docRef.get().then(function(doc) {
                if (doc.exists) {

                    if(doc.data().orientadorMail != ls.get('mail')){
                        console.log('Esse projeto não é seu!')
                        setRecado("Este projeto não é seu")
                        
                    } else {
                        setRecado("O e-mail do projeto foi confirmado com sucesso!")
                        change_data()
                    };

                } else {
                    
                    docIdeaRef.get().then(function(doc) {
                        if (doc.exists) {
                            console.log(doc.data().orientadorMail)
                            console.log(ls.get('mail'))
                            if (doc.data().orientadorMail != ls.get('mail')){
                                console.log('Essa ideia não é sua!')
                                setRecado('Esta ideia não é sua')
                            } else {
                                setRecado("O e-mail da ideia foi confirmado com sucesso!")
                                change_idea_data()
                            }
                        } else {
                            console.log("Esse ID não existe!");
                            setRecado("Este ID não existe")
                        }
                    })
                    
                }

            }).catch(function(error) {
                console.log("Error getting document:", error);
                setRecado("Erro ao adquirir o documento")
            });


            
    }

    const change_data = async () => {
        let database_cards = db.collection('cards').doc(`ID: ${project_idd}`);
            await database_cards.update({
                email_confirmado: true
            })

    }

    const change_idea_data = async () => {
        let database_idea_cards = db.collection('ideias').doc(`ID: ${project_idd}`);
        await database_idea_cards.update({
            email_confirmado: true
        })
    }

    return (
        <div className="cards">
            <h1>Seu e-mail foi confirmado com sucesso! Agora basta digitar o ID do projeto:</h1>
            <div className='cards__container'>
            <input className='form_modified' placeholder="id do projeto exemplo: 0.786889413357158" value={project_idd} onChange={project_id}/>      
            <p>{recado}</p>
            <button placeholder="Confirmar" className="confirmer" onClick={() => {confirmar_mail()}}>Confirmar </button>
            </div>
        </div>
    )
}



export default Confirmar


