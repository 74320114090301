import YouTube from 'react-youtube';
import React, { useState, useEffect } from 'react';
import '../../App.css';


export default function YoutubeVideo () {
    


    const _onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
      }


    const opts = {
        height: '390',
        width: '640',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
      };
    
    
    return (

            <div className="superContainer" >
                <div className="videoContainer">
                    <YouTube videoId="i46J3yyk8jE" opts={opts} onReady={_onReady} className="video"/>
                </div>
            </div>

    )


}