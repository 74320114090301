
import React, { useState } from 'react';
import './Intro.css';
import '../../App.css';
import {useSpring, animated} from 'react-spring'
import {Button_more} from '../Buttons/Button-more'

function Intro() {

    const [toggle, setToggle] = React.useState(true)
    const propss = useSpring({opacity: toggle ? 1 : 0})
    

    return (
        <div className='hero-container'>
            <img  className='videoo' />
            <h1>Integra</h1>
            <p>A primeira plataforma de integração de projetos do CEFET/RJ</p>

            <div className="hero-btns">
                <Button_more className='btns' buttonStyle='btn--primary'
                buttonSize='btn--outline'>
                    SAIBA MAIS 
                </Button_more>
            </div>
        </div>
    )
}

export default Intro

