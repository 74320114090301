import React from 'react';
import Navbar from './components/Navbar/Navbar';
import './App.css'

import Projetos from './components/pages/Projetos'
import Home from './components/pages/Home.js'
import Info from './components/pages/Info'
import SignUp from './components/pages/SignUp'
import Confirmar from './components/pages/confirmando'
import Ideias from './components/pages/Ideias'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home}  />
          <Route path='/info' component={Info} />
          <Route path='/cadastro' component={SignUp} />
          <Route path='/projetos' component={Projetos} />
          <Route path='/confirmar' component={Confirmar} />
          <Route path='/ideias' component={Ideias} />
        </Switch>
      </Router>
    </>
  );
}

export default App;