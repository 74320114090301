import '../../App.css'
import Intro from '../Home/Intro.js';
import Cards from '../Home/Cards.js'

import React from 'react'

function Info(props) {
    return (
        <>
            <Intro />
            <Cards />
        </>
    )
}


export default Info

