import '../../App.css'
import Intro from '../cadastro/Intro_register.js';
import Cards from '../Home/Cards.js'

import React from 'react'

function SignUp(props) {
    return (
        <>
            <Intro />
        </>
    )
}


export default SignUp

