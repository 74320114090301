import '../../App.css'
import Projetos_list from '../Lista_de_projetos/Projetos'

import React from 'react'

function Projetos(props) {
    return (
        <>
            <Projetos_list />
        </>
    )
}


export default Projetos

