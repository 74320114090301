import React from 'react'
import '../Lista_de_projetos/Card.css'
import Start_card from '../CardItem/Start_card.js'

function Cards() {
    return (
        <div className="cards">
            <h1>Confira alguns projetos!</h1>
            <div className='cards__container'>
                <div className="cards__wrapper">
                    <ul className="cards__items">

                        <Start_card
                        src='https://firebasestorage.googleapis.com/v0/b/projetointegra-5e4cf.appspot.com/o/Hero.jpg?alt=media&token=e6800a01-f13d-46a9-a29e-1222b6e1a61a'
                        text="Os 3 primeiros a passarem o ID do projeto para o e-mail: projetointegracefetrj@gmail.com"
                        label="Rápido!"
                        
                        />

                        <Start_card 
                        src='https://firebasestorage.googleapis.com/v0/b/projetointegra-5e4cf.appspot.com/o/Hero.jpg?alt=media&token=e6800a01-f13d-46a9-a29e-1222b6e1a61a'
                        text="Os 3 primeiros a passarem o ID do projeto para o e-mail: projetointegracefetrj@gmail.com"
                        label="Rápido!"
                                               />

                    </ul>
                    <ul className="cards__items">

                        <Start_card 
                        src='https://firebasestorage.googleapis.com/v0/b/projetointegra-5e4cf.appspot.com/o/Hero.jpg?alt=media&token=e6800a01-f13d-46a9-a29e-1222b6e1a61a'
                        text="Os 3 primeiros a passarem o ID do projeto para o e-mail: projetointegracefetrj@gmail.com"
                        label="Rápido!"
                        
                        />

                    </ul>
                </div>
            </div>
        </div>
    )
}



export default Cards

