import React, {useState, useEffect} from 'react'
import './Card.css'
import CardItem from '../CardItem/CardItem'
import {db} from '../../server'
import { Link, animateScroll as scroll } from "react-scroll";
import Select from 'react-select'


function Projetos_list() {





    
    const [description, setDescription] = React.useState([])
    const [names, setNames] = React.useState([])
    const [disponivel, setDisponivel] = React.useState([])
    const [fase_do_projeto, setFase_do_projeto] = React.useState([])
    const [linkForm, setLinkForm] = React.useState([])
    const [mailproject, setMailproject] = React.useState([])
    const [image, setImage] = React.useState([])
    const [categoria, setCategoria] = React.useState([])
    const [participante1, setParticipante1] = React.useState([])
    const [participante2, setParticipante2] = React.useState([])
    const [participante3, setParticipante3] = React.useState([])
    const [participante4, setParticipante4] = React.useState([])
    const [participante5, setParticipante5] = React.useState([])
    const [participante6, setParticipante6] = React.useState([])
    const [participante7, setParticipante7] = React.useState([])
    const [orientador1, setOrientador1] = React.useState([])
    const [orientador2, setOrientador2] = React.useState([])
    const [instagram, setInstagram] = React.useState([])
    const [twitter, setTwitter] = React.useState([])
    const [facebook, setFacebook] = React.useState([])
    const [preenchimaster, setPreenchimaster] = React.useState([])
    const [campi, setCampi] = React.useState([])
    const [ensino, setEnsino] = React.useState([])
    const [state, setState] = React.useState(true)
    const [campus, setCampus_do_projeto] = React.useState([])




    
    var cardList = []
    var cardId = []

    const selector_handler = e => {
      setCampus_do_projeto(e.value);
    }



    const gettingData = async () => {
        let cards_database = db.collection('cards')
        cards_database.get().then(snapshot => {
          snapshot.forEach(doc => {
            if(doc.data().aprovado == 1){
              cardList.push(doc.data())
              cardId.push(doc.id);
            }
          });
        })
        .catch(err => {
          console.log('Error getting documents', err);
        });
      }
    
      useEffect(() => {
        gettingData()
        }
      )

      const returning = () => {
        if (cardList.length != 0){
    
        var namess = []
        var descriptionss = []
        var disponivell = []
        var fase_do_projetoo = []
        var linkFormss = []
        var imagess = []
        var categoriass = []
        var mailssproject = []
        var pparticipante1 = []
        var pparticipante2 = []
        var pparticipante3 = []
        var pparticipante4 = []
        var pparticipante5 = []
        var pparticipante6 = []
        var pparticipante7 = []
        var oorientador1  = []
        var oorientador2 = []
        var ffacebook = []
        var ttwitter = []
        var iinstagram = []
        var eensino = []
        var ccampi = []

        for (let i = 0; i < cardList.length; i++){
    
          namess.push(cardList[i].name)
          descriptionss.push(cardList[i].description)
          disponivell.push(cardList[i].disponivel)
          fase_do_projetoo.push(cardList[i].fase_do_projeto)
          linkFormss.push(cardList[i].linkForm)
          mailssproject.push(cardList[i].mail_project)
          imagess.push(cardList[i].image)
          categoriass.push(cardList[i].categoria)
          pparticipante1.push(cardList[i].participante1)
          pparticipante2.push(cardList[i].participante2)
          pparticipante3.push(cardList[i].participante3)
          pparticipante4.push(cardList[i].participante4)
          pparticipante5.push(cardList[i].participante5)
          pparticipante6.push(cardList[i].participante6)
          pparticipante7.push(cardList[i].participante7)
          oorientador1.push(cardList[i].orientador)
          oorientador2.push(cardList[i].orientador2)
          ffacebook.push(cardList[i].facebook)
          ttwitter.push(cardList[i].twitter)
          iinstagram.push(cardList[i].instagram)
          ccampi.push(cardList[i].campi)
          eensino.push(cardList[i].ensino)
    

    
          
    
        }
        setNames(namess)
        setDescription(descriptionss)
        setDisponivel(disponivell)
        setFase_do_projeto(fase_do_projetoo)
        setLinkForm(linkFormss)
        setMailproject(mailssproject)
        setImage(imagess)
        setCategoria(categoriass)
        setParticipante1(pparticipante1)
        setParticipante2(pparticipante2)
        setParticipante3(pparticipante3)
        setParticipante4(pparticipante4)
        setParticipante5(pparticipante5)
        setParticipante6(pparticipante6)
        setParticipante7(pparticipante7)
        setOrientador1(oorientador1)
        setOrientador2(oorientador2)
        setFacebook(ffacebook)
        setTwitter(ttwitter)
        setInstagram(iinstagram)
        setEnsino(eensino)
        setCampi(ccampi)
    
        }
      }

      const repeater = () => {
        if (names.length == 0){
          setTimeout(returning, 6000)
        }
      }

      repeater()

      const Project_cards = (number) => {
          if (names.length == 0){
              return (
                <CardItem label='Carregando...' text='Carregando...'/>
              )
          } else if (categoria[number] == "" || categoria[number] == undefined) {
              return (
                <CardItem label='Local Vago' text='Local Vago' to="/cadastro" />
              )
          } else if (campus != '' && campus != 'Todos'){
            
              if (campi[number] == campus){
                
                return (
                  <CardItem src={image[number]} text={description[number]} label={names[number]} 
                    participantes={participante1[number] + ", " + participante2[number] + ", " + participante3[number] + ", " + participante4[number] + ", " + participante5[number] + ", " + participante6[number] + ", " + participante7[number]}
                    orientadores={orientador1[number] + ", " + orientador2[number]}
                    formLink={linkForm[number]}
                    disponivel={disponivel[number]}
                    categoria={categoria[number]}
                    fase_do_projeto={fase_do_projeto[number]}
                    projectmail={mailproject[number]}
                    twitter={twitter[number]}
                    instagram={instagram[number]}
                    facebook={facebook[number]} 
                    campi={campi[number]}
                    ensino={ensino[number]}/>
                  )
              }
            
          } else {
              return (
                <CardItem src={image[number]} text={description[number]} label={names[number]} 
                participantes={participante1[number] + ", " + participante2[number] + ", " + participante3[number] + ", " + participante4[number] + ", " + participante5[number] + ", " + participante6[number] + ", " + participante7[number]}
                orientadores={orientador1[number] + ", " + orientador2[number]}
                formLink={linkForm[number]}
                disponivel={disponivel[number]}
                categoria={categoria[number]}
                fase_do_projeto={fase_do_projeto[number]}
                projectmail={mailproject[number]}
                twitter={twitter[number]}
                instagram={instagram[number]}
                facebook={facebook[number]} 
                campi={campi[number]}
                ensino={ensino[number]}/>
              )

          }
      }

      const Preenchedor = () => {
          var preenchedor = []
          for (let i = 0; i < 10; i++){
              preenchedor.push(i)
          }
          setPreenchimaster(preenchedor)
          
          
      }

      if (state) {
          Preenchedor()
          setState(false)
        }
 
      
        const options = [
          { value: 'Maracanã', label: 'Maracanã' },
          { value: 'Angra dos Reis', label: 'Angra dos Reis' },
          { value: 'Itaguaí', label: 'Itaguaí' },
          { value: 'Maria da Graça', label: 'Maria da Graça' },
          { value: 'Nova Friburgo', label: 'Nova Friburgo' },
          { value: 'Nova Iguaçu', label: 'Nova Iguaçu' },
          { value: 'Petrópolis', label: 'Petrópolis' },
          { value: 'Valença', label: 'Valença' },
          { value: 'Todos', label: 'Todos' }
        ]

    return (
        <div className="cards" id="start">
            <h1>Confira alguns projetos!</h1>
            <p className="centertext"> Para participar ou para saber mais, basta clicar no projeto</p>

            <div className="select_centralizer"> 
              <Select 
                options={options} 
                className="seletor_master" 
                placeholder="Selecione" 
                value={options.find(obj => obj.value === fase_do_projeto)}
                onChange={selector_handler}
              />
            </div>


            <div className='cards__container'>

                <div className="button_master_footer">

                        <div className="button_preenchedor_top" onClick={() => {setPreenchimaster(preenchimaster.map(function(x) {return (x + 10)})) 
                        console.log(preenchimaster)}}>                                                                                                                   
                            <p className="carregarmaisfonte"> Carregar Mais </p>
                        </div>

                        <div className="button_preenchedor_top" onClick={() => {
                            if (preenchimaster[0] != 0){
                                console.log(preenchimaster)
                            setPreenchimaster(preenchimaster.map(function(x) {return (x - 10)}))
                            console.log(preenchimaster)} }}>

                            <p className="carregarmaisfonte"> Voltar </p>
                        </div>
                </div>

                <div className="cards__wrapper">
                    <ul className="cards__items">

                        {Project_cards(preenchimaster[0])}

                        {Project_cards(preenchimaster[1])}

                        {Project_cards(preenchimaster[2])}

                    </ul>

                    <ul className="cards__items">

                        {Project_cards(preenchimaster[3])}

                        {Project_cards(preenchimaster[4])}

                    </ul>

                    <ul className="cards__items">

                        {Project_cards(preenchimaster[5])}

                        {Project_cards(preenchimaster[6])}

                        {Project_cards(preenchimaster[7])}

                    </ul>

                    <ul className="cards__items">

                        {Project_cards(preenchimaster[8])}

                        {Project_cards(preenchimaster[9])}

                    </ul>

                </div>
                <div className="button_master_footer">

                    <Link activeClass="active" to="start" spy={true} smooth={true} offset={-70} duration={500}>
                        <div className="button_preenchedor" onClick={() => {setPreenchimaster(preenchimaster.map(function(x) {return (x + 10)})) 
                        console.log(preenchimaster)}}>                                                                                                                   
                            <p className="carregarmaisfonte"> Carregar Mais </p>
                        </div>
                    </Link>

                    <div className="button_preenchedor" onClick={() => {
                        
                        
                        
                        if (preenchimaster[0] != 0){
                            console.log(preenchimaster)
                        setPreenchimaster(preenchimaster.map(function(x) {return (x - 10)}))
                        console.log(preenchimaster)} }}>

                        <p className="carregarmaisfonte"> Voltar </p>
                    </div>
                </div>
            </div>
            
        </div>
    )
}



export default Projetos_list

