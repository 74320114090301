import '../../App.css'
import Intro from '../Home/Intro.js';
import Confirmando from '../Componente_confirmar/Confirmando.js'
import firebase from '../../server.js'
import ls from 'local-storage'

import React, {useState} from 'react'

function Confirmar(props) {

    const [confirmado, setConfirmado] = React.useState('')
    const [once, setOnce] = React.useState(true)
    const [once2, setOnce2] = React.useState(true)
    

    const autenticador = async () => {

        console.log(firebase.auth().isSignInWithEmailLink(window.location.href))

        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {

            var email = ls.get('mail');
            
            if (once2){
                if (!email) {

                    email = window.prompt('Por favor digite o e-mail que usou para confirmar');
                    ls.set('mail', email)

                }
                setOnce2(false)
            }

            firebase.auth().signInWithEmailLink(email, window.location.href)
            .then((result) => {
               

                

        })
            .catch((error) => {
                console.log(error)
        });
            setTimeout(() => {setConfirmado(true)}, 3000)
            
        }
    }
        
    

    if (once){
        autenticador()
        setOnce(false)
    }
    

    if (confirmado != null && confirmado != '' && confirmado != false){
        
        return (
            <>
                
                <Confirmando />

            </>
        )
    } else {
        
        return (
            <>

              <p className="loading_Error"> Carregando... (Se a aba de confirmação não carregar, significa que você não confirmou seu e-mail) </p>  

            </>
        )
    }
    
}


export default Confirmar

