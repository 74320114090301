import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './CardItem.css'
import {useSpring, animated} from 'react-spring'

function CardItem(props) {
  const [click, setClicked] = React.useState(0)

  const propss = useSpring({opacity: click, from: {opacity: 0}})


  const butonneiro = () => {
    console.log(props.disponivel)
    if (props.disponivel){
      return (
        <a className="butoneiro" href={props.formLink} target="_blank">Clique aqui para participar!</a>
      )
    } else {
      return ( null )
    }
  }

  const social_media_master = () => {
    if (props.facebook != '' && props.twitter != '' && props.instagram != '') {
    return (
      <div className="social_media_div">

        <a href={props.facebook}>
          <img src="./images/insta (1).png" className="facebook"/>
        </a>

        <a href={props.twitter}>
          <img src="./images/insta (2).png" className="twitter"/>
        </a>

        <a href={props.instagram}>
          <img src="./images/insta (3).png" className="instagram"/>
        </a>

      </div>
    ) 
  } 
  else if (props.instagram != '' && props.twitter != ''){
    return (
      <div className="social_media_div">

        <a href={props.instagram}>
          <img src="./images/insta (3).png" className="instagram"/>
        </a>

        <a href={props.twitter}>
          <img src="./images/insta (2).png" className="twitter"/>
        </a>

      </div>
    ) 

  }
  else if (props.facebook != '' && props.instagram != ''){
    return (
      <div className="social_media_div">

        <a href={props.facebook}>
          <img src="./images/insta (3).png" className="facebook"/>
        </a>

        <a href={props.instagram}>
          <img src="./images/insta (1).png" className="instagram"/>
        </a>

      </div>
    ) 

  }
  else if (props.facebook != '' && props.twitter != ''){
    return (
      <div className="social_media_div">

        <a href={props.twitter} >
          <img src="./images/insta (2).png" className="twitter"/>
        </a>

        <a href={props.facebook}>
          <img src="./images/insta (1).png" className="facebook"/>
        </a>

      </div>
    ) 

  }
  else if (props.facebook!= ''){
    return (
      <div className="social_media_div">

        <a href={props.facebook}>
          <img src="./images/insta (3).png" className="instagram"/>
        </a>

      </div>
    )

  }
  else if (props.twitter != ''){
    return (
      <div className="social_media_div">

          <a href={props.twitter}> 
            <img src="./images/insta (2).png" className="twitter"/>
          </a>
          
      </div>
    )

  }
  else if (props.instagram){
    return (
      <div className="social_media_div">

          <a href={props.instagram}>
            <img src="./images/insta (1).png" className="facebook"/>
          </a>
          
      </div>
    )

  }
  else {
    return null
  }

  }


  const Checker = () => {
    if (click == 0){
      return (
        <>
          <li className='cards__item' onClick={() => {setClicked(1)}}>
            <Link className='cards__item__link'>
              <figure className='cards__item__pic-wrap' data-category={props.label}>
                <img
                  className='cards__item__img'
                  alt=''
                  src={props.src}
                />
              </figure>
              <div className='cards__item__info'>
                <h5 className='cards__item__text'>{props.text}</h5>
                <p className="testador">{props.ensino} | {props.campi}</p>
              </div>
            </Link>
          </li>
        </>
      );
  } else {
    return (


        <div className="genDiv" >
          <animated.div className="modal_content" style={propss}>
            <div className="imagedescription">
              <img src={props.src} className="popupimage"/>
              <p className="project_description_master">{props.text}
              </p>
            </div>
            
            <div className="info_column">
              <p className="project_title">{props.label}</p>
              <div className="maxfount">
                <p className="fount"> Fase do projeto: {props.fase_do_projeto} </p>
                <p className="fount"> Meio de contato: {props.projectmail} </p>
              </div>

              <div className="maxfount">
                <p className="fount_part"> Participantes: {props.participantes}</p>
                <p className="fount_part"> Líder: {props.orientadores}</p>
              </div>

                {butonneiro()}

            </div>

            <div className="campi_ensino">
              <p className="campi_ensino_text">Campus: {props.campi}</p>
              <p className="campi_ensino_text">Ensino: {props.ensino}</p>
            </div>

            <div className="category-color">
              <p className="category-text">{props.categoria}</p>

            </div>

            {social_media_master()}

            <img src="./images/x.png" className="exit" onClick={() => {setClicked(0)}} />

            

          </animated.div>
          
          <div className="modal" onClick={() => setClicked(0)}>
          </div>
          
        </div>

    )
  }
}

  return (
    <>
      {Checker()}
    </>
  )
} 

export default CardItem;