import React, { useState, useEffect } from 'react';
import { Button } from '../Buttons/Button';
import { Link } from 'react-router-dom';
import { Link as Linkerr, animateScroll as scroll } from "react-scroll";

import './Navbar.css';
import Aos from "aos";

function Navbar() {

  useEffect(() => {
    Aos.init({duration: 2000});
  }, []);

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            Integra
            <i class='fab fa-typo3' />
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Início
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/projetos'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Projetos
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/ideias'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Ideias
              </Link>
            </li>
            <li className='nav-item'>
              <Linkerr
                to="Informacoes" spy={true} smooth={true} offset={-70} duration= {2000} 
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Informações
              </Linkerr>
            </li>
            
            <li>
              <Link
                to='/cadastro'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                CADASTRE SEU PROJETO!
              </Link>
            </li>
          </ul>
          {button && <Button buttonStyle='btn--outline'>CADASTRE SEU PROJETO!</Button>}
        </div>
      </nav>
    </>
  );
}

export default Navbar;

