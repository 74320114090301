
import React, { useState } from 'react';
import './Intro_register.css';
import '../../App.css';
import {useSpring, animated} from 'react-spring'
import {Button_cadastro} from '../Buttons/Button-cadastro'
import { Link } from 'react-router-dom';
import Select from 'react-select'
import firebase from '../../server.js'
import { db } from '../../server.js'
import ls from 'local-storage'

import 'firebase/storage'; 
import 'firebase/auth';

function Intro() {

    const [open, setOpen] = React.useState(false)
    const [escrito, setEscrito] = React.useState('Categoria')
    const [recado, setRecado] = React.useState('')
    /* ---------------------------- */
    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [fase_do_projeto, setFase_do_projeto] = React.useState('')
    const [disponivel, setDisponivel] = React.useState('')
    const [linkForm, setLinkForm] = React.useState('')
    const [email_projeto, setEmail_projeto] = React.useState('')
    const [categoria, setCategoria] = React.useState('Categoria')
    const [popup, setPopup] = React.useState(false)

    const [orientador, setOrientador] = React.useState('')
    const [mailOrientador, setMailOrientador] = React.useState('')
    const [orientador2, setOrientador2] = React.useState('')
    const [participante1, setParticipante1] = React.useState('')
    const [participante2, setParticipante2] = React.useState('')
    const [participante3, setParticipante3] = React.useState('')
    const [participante4, setParticipante4] = React.useState('')
    const [participante5, setParticipante5] = React.useState('')
    const [participante6, setParticipante6] = React.useState('')
    const [participante7, setParticipante7] = React.useState('')
    const [randomm, setRandom] = React.useState('')

    const [campi, setCampi] = React.useState('')
    const [ensino, setEnsino] = React.useState('')
    
    const [description, setDescription] = React.useState('')
    const [image, setImage] = React.useState("./images/Upload.png")
    const [classImage, setclassImage] = React.useState('uploadImageIcon')

    const [facebook, setFacebook] = React.useState('')
    const [instagram, setInstagram] = React.useState('')
    const [twitter, setTwitter] = React.useState('')
    const [linkedin, setLinkedin] = React.useState('')

    /* --------------------------------------------------------------------------- */

    const handleName = (event) => {
        setName(event.target.value);
    }

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handleFase_do_projeto = e => {
        setFase_do_projeto(e.value);
    }

    const handleLinkForm = (event) => {
        setLinkForm(event.target.value);
    }

    const handleCategoria = e => {
        setCategoria(e.value);
    }

    const handleEmail_projeto = (event) => {
        setEmail_projeto(event.target.value);
    }

    const handleEnsino = e => {
        setEnsino(e.value);
    }

    const handleCampi = e => {
        setCampi(e.value);
    }

    /* -------------------------- */

    const handleOrientador1 = (event) => {
        setOrientador(event.target.value);
    }

    const handleOrientador2 = (event) => {
        setOrientador2(event.target.value);
    }

    const handleParticipante1 = (event) => {
        setParticipante1(event.target.value);
    }

    const handleParticipante2 = (event) => {
        setParticipante2(event.target.value);
    }

    const handleParticipante3 = (event) => {
        setParticipante3(event.target.value);
    }

    const handleParticipante4 = (event) => {
        setParticipante4(event.target.value);
    }

    const handleParticipante5 = (event) => {
        setParticipante5(event.target.value);
    }

    const handleParticipante6 = (event) => {
        setParticipante6(event.target.value);
    }

    const handleParticipante7 = (event) => {
        setParticipante7(event.target.value);
    }

    /* -------------------------- */

    const handleDescription = (event) => {
        setDescription(event.target.value);
    }

    const handleImage = (event) => {
        setImage(URL.createObjectURL(event.target.files[0]))
        console.log(event.target.files)
        setclassImage("cardimage")
    }

    const handleFacebook = (event) => {
        setFacebook(event.target.value)
    }

    const handleTwitter = (event) => {
        setTwitter(event.target.value)
    }

    const handleInstagram = (event) => {
        setInstagram(event.target.value)
    }

    const handleLinkedin = (event) => {
        setLinkedin(event.target.value)
    }

    const handleMailOrientador = (event) => {
        setMailOrientador(event.target.value)
        console.log(mailOrientador)
        console.log(mailOrientador.search("@cefet-rj.br"))
    }

    /* -------------------------- */
    const resetForm = () => {

        setName('')
        setEmail('')
        setFase_do_projeto('')
        setDisponivel('')
        setLinkForm('')
        setEmail_projeto('')
        setOrientador('')
        setOrientador2('')
        setParticipante1('')
        setParticipante2('')
        setParticipante3('')
        setParticipante4('')
        setParticipante5('')
        setParticipante6('')
        setParticipante7('')
        setDescription('')
        setImage("./images/Upload.png")
        setclassImage('uploadImageIcon')
        setRecado('')
        setFacebook('')
        setInstagram('')
        setTwitter('')
        setLinkedin('')
        setMailOrientador('')
        setCampi('')
        setEnsino('')
        

    }

    var actionCodeSettings = {
        url: 'https://projetointegra-5e4cf.web.app/confirmar',
        handleCodeInApp: true,
    };
    
    const confirm_mail = async () => {
        firebase.auth().sendSignInLinkToEmail(mailOrientador, actionCodeSettings)
    }


    const submit = async () => {
 
        if (name != '' && email != '' && mailOrientador.search("@cefet-rj.br") != -1 && mailOrientador.search("1") == -1 && mailOrientador.search("2") == -1 && mailOrientador.search("3") == -1 && mailOrientador.search("4") == -1 && mailOrientador.search("5") == -1 && mailOrientador.search("6") == -1 && mailOrientador.search("7") == -1 && mailOrientador.search("8") == -1 && mailOrientador.search("9") == -1 && mailOrientador.search("0") == -1 && mailOrientador != '' &&  mailOrientador.search("@aluno") == -1 
        && fase_do_projeto != '' && (disponivel == true || disponivel == false) && email_projeto != '' && categoria != '' && campi != '' && ensino != '' && participante1 != '' && description != '' && image != "./images/Upload.png" && !(disponivel == true && linkForm == ''))  {
            console.log(image)
            confirm_mail()
            const response = await fetch(image);
            const blob = await response.blob();
            var random = Math.random();
            setRandom(random)
    
            console.log(firebase.storage())

            var upload = await firebase.storage().ref().child(`images/${random}`)
            await upload.put(blob)
    
            var download = await upload.getDownloadURL();

            let database_cards = db.collection('cards').doc(`ID: ${random}`);

            download = await upload.getDownloadURL()
            console.log('Amigo, seta no local storage por favor')
            ls.set('mail', mailOrientador)
            await database_cards.set({
                name: name,
                mail: email,
                description: description,
                mail_project: email_projeto,
                linkForm: linkForm,
                disponivel: disponivel,
                aprovado: 0,
                orientador: orientador,
                orientador2: orientador2,
                orientadorMail: mailOrientador,
                participante1: participante1,
                participante2: participante2,
                participante3: participante3,
                participante4: participante4,
                participante5: participante5,
                participante6: participante6,
                participante7: participante7,
                facebook: facebook,
                instagram: instagram,
                twitter: twitter,
                image: download,
                categoria: categoria,
                fase_do_projeto: fase_do_projeto,
                email_confirmado: false,
                campi: campi,
                ensino: ensino
            })
            console.log('Finalizado')
            setRecado("Seu projeto foi enviado com sucesso para a aprovação!")
            setTimeout(resetForm, 100)
            setPopup(true)
        }
        else if (name == '') {
            setRecado('Erro: Preencha o título')
        } 
        else if (email == ''){
            setRecado('Erro: Preencha seu email')
        } 
        else if (description == ''){
            setRecado('Erro: Seu projeto precisa de uma breve descrição!')
        }
        else if (email_projeto == ''){
            setRecado('Erro: Seu projeto precisa ter um meio de contato!')
        }
        else if (disponivel == true && linkForm == ''){
            setRecado('Erro: Você colocou que os alunos podem entrar no projeto mas não colocou como!')
        }
        else if (disponivel == ''){
            setRecado('Erro: Responda se seu projeto está disponível para alunos entrarem (Pode ser alterado no futuro!)')
        }
        else if (orientador == ''){
            setRecado('Erro: Preencha o campo do orientador. Caso seu projeto não tenha orientador vá para a aba ideias')
        }
        
        else if (mailOrientador.search("@aluno") != -1) {
            console.log(mailOrientador.search("@aluno"))
            setRecado('Erro: Seu e-mail possui a marca de aluno (@aluno)')
        }

        else if (mailOrientador.search("@cefet-rj.br") == -1){
            console.log(mailOrientador)
            console.log(mailOrientador.search("@cefet-rj.br"))
            setRecado('Erro: Preenchimento o e-mail do orientador // Corrija a formatação do e-mail para nome.nome@cefet-rj.br')
        }

        else if (mailOrientador.search("1") != -1 || mailOrientador.search("2") != -1 || mailOrientador.search("3") != -1 || mailOrientador.search("4") != -1 || mailOrientador.search("5") != -1 || mailOrientador.search("6") != -1 || mailOrientador.search("7") != -1 || mailOrientador.search("8") != -1 || mailOrientador.search("9") != -1 || mailOrientador.search("0") != -1){
            setRecado('Erro: Seu e-mail possui números')
            console.log(mailOrientador)
        }

        else if (participante1 == ''){
            setRecado('Erro: Seu projeto precisa ter pelo menos um participante e deve ser escrito na ordem correta!')
        }
        else if (image == ''){
            setRecado('Erro: Seu projeto precisa de uma imagem!')
        }
        else if (categoria == ''){
            setRecado('Erro: Selecione uma categoria para seu projeto')
        }
        else if (fase_do_projeto == ''){
            setRecado('Erro: Selecione a fase de seu projeto')
        }
        else if (campi == ''){
            
            setRecado('Erro: Selecione o Campi do projeto')
        }

        else if (ensino == ''){
            setRecado('Erro: Selecione o ensino (Técnico, superior ou ambos)')
        }
        }
        

    

    const options = [
        { value: 'Inicial', label: 'Inicial' },
        { value: 'intermediária', label: 'Intermediária' },
        { value: 'Final', label: 'Final' },
        { value: 'Cíclico', label: 'Cíclico' },
        { value: 'Ideia', label: 'Ideia' },
      ]

      const options2 = [
        { value: 'Engenharia e tecnologias', label: 'Engenharia e tecnologias' },
        { value: 'Ciências exatas', label: 'Ciências exatas' },
        { value: 'Ciências da terra', label: 'Ciências da terra' },
        { value: 'Interdisciplinar', label: 'Interdisciplinar' },
        { value: 'Ciências Humanas', label: 'Ciências Humanas' },
        { value: 'Treinamentos e/ou cursos', label: 'Treinamentos e/ou cursos' },
        { value: 'Grupo de projetos', label: 'Grupo de projetos' }


      ]

      const ensino_options = [
        { value: 'Técnico', label: 'Técnico' },
        { value: 'Graduação', label: 'Graduação' },
        { value: 'Ambos', label: 'Ambos' }
      ]

      const campi_options = [
        { value: 'Maracanã', label: 'Maracanã' },
        { value: 'Angra dos Reis', label: 'Angra dos Reis' },
        { value: 'Itaguaí', label: 'Itaguaí' },
        { value: 'Maria da Graça', label: 'Maria da Graça' },
        { value: 'Nova Friburgo', label: 'Nova Friburgo' },
        { value: 'Nova Iguaçu', label: 'Nova Iguaçu' },
        { value: 'Petrópolis', label: 'Petrópolis' },
        { value: 'Valença', label: 'Valença' }


      ]

    
    const idpopup = (props) => {
        if (popup){
            return (
            <div className="genDiv">
                <animated.div className="modal_content" style={propss}>

                <div className="info_column_register_popp">
                    <p className="project_title">Seu ID é {randomm}</p>
                    <p>Este ID é super importante para que você possa editar, retirar ou efetuar qualquer pedido para seu projeto no futuro, 
                        por favor, salve este número e não o perca! Para finalizar o cadastro do seu projeto, confirme seu e-mail institucional. Para isso, basta
                        acessar seu e-mail, uma mensagem automática foi enviada para lá, e clicar em login. Depois, você terá que utilizar este ID para confirmar o e-mail no projeto.
                        Verifique o SPAM do seu E-mail! Geralmente a mensagem automática vai para lá!
                    </p>
                </div>
                


                <img src="./images/x.png" className="exit" onClick={() => {setPopup(false)}} />

            

          </animated.div>
          
          <div className="modal" onClick={() => setPopup(false)}>
          </div>
          
        </div>
            )
        }
    }

    const propss = useSpring({opacity: 1, from: {opacity: 0}})

    return (
        <div className='hero-container2'>
            <img  className='videoo' />
            <animated.div style={propss} className='hero-container2'>
            <h1 className="kinger">Cadastro</h1>
            <p className="kinger_text">Preencha o formulário para cadastrar o projeto.
                Para cadastrar um projeto que ainda não possui orientador vá para a aba cadastrar ideias!
            </p>

            <div className="centralizer">
                <div className="columner">
                    <p className="marger"> Informações sobre o projeto </p>
                    <p className="formtext">Título do projeto</p>
                    <input className="form" placeholder="Título" maxLength={300} value={name} onChange={handleName}/>

                    <p className="formtext">Seu E-mail (Não ficará público)</p>
                    <input className="form" type="email" placeholder="E-mail" maxLength={300} value={email} onChange={handleEmail}/>

                    <p className="formtext">Fase do projeto</p>
                    <Select 
                    options={options} 
                    className="selector1" 
                    placeholder="Selecione" 
                    value={options.find(obj => obj.value === fase_do_projeto)}
                    onChange={handleFase_do_projeto}/>
                    
                    <p className="formtext" className="formtext3">Seu projeto está disponível para os alunos(as) entrarem?</p>

                    <div className="radio_flex">
                        <input type="radio" value="recruit" name="recruit" onClick={() => {setDisponivel(true)}}/>
                        <p className="sim" > Sim </p>
                        <input type="radio" value="recruit" name="recruit" onClick={() => {setDisponivel(false)}}/>
                        <p className="nao" > Não </p>
                    </div>
                    
                    <p className="formtext3">Diga por onde os alunos(as) podem participar (Recomendamos um formulário!)</p>
                    <input className="form" placeholder="Link do formulário ou E-mail" maxLength={100} 
                    onChange={handleLinkForm} value={linkForm}/>  

                    <p className="formtext3">Meio de contato (E-mail ou telefone, ficará público!)</p>
                    <input className="form" type="email" placeholder="E-mail de contato do projeto" maxLength={100} 
                    onChange={handleEmail_projeto} value={email_projeto}/>  

                    <p className="formtext">Categoria</p>
                    <Select options={options2} className="selector1" placeholder="Selecione"
                    value={options2.find(obj => obj.value === categoria)}
                    onChange={handleCategoria} />

                    <p className="formtext">Ensino</p>
                    <Select options={ensino_options} className="selector1" placeholder="Selecione"
                    value={ensino_options.find(obj => obj.value === ensino)}
                    onChange={handleEnsino} />
                    
                    <p className="formtext">Campus</p>
                    <Select options={campi_options} className="selector1" placeholder="Selecione"
                    value={campi_options.find(obj => obj.value === campi)}
                    onChange={handleCampi} />

                    <p className="marger"> Se os links abaixo não se aplicarem a você, deixe em branco</p>

                    <p className="formtext">Link do facebook (Apenas o link, nada a mais!)</p>
                    <input className="form" placeholder="Link do facebook" maxLength={100} value={facebook} onChange={handleFacebook}/>  

                    <p className="formtext">Link do instagram (Apenas o link, nada a mais!)</p>
                    <input className="form" placeholder="Link do instagram" maxLength={100} value={instagram} onChange={handleInstagram}/>  

                    <p className="formtext">Link do twitter (Apenas o link, nada a mais!)</p>
                    <input className="form" placeholder="Link do twitter" maxLength={100} value={twitter} onChange={handleTwitter}/>  
                    
                </div>

                <div className="columner">

                    <p className="marger"> Integrantes do projeto </p>

                    <p className="formtext">Orientador</p>
                    <input className="form" placeholder="Orientador" maxLength={100} 
                    onChange={handleOrientador1}
                    value={orientador}/>  

                    <p className="formtext">E-mail do Orientador Principal (Será confirmado!)</p>
                    <p className="moredescription"> O e-mail do orientador deve estar no formato nome.sobrenome@cefet-rj.br Registro com CPF não será aceito! Não ficará público</p>
                    <input className="form" placeholder="E-mail do Orientador" type="email" maxLength={100} 
                    onChange={handleMailOrientador}
                    value={mailOrientador}/>  

                    <p className="formtext">Orientador 2</p>
                    <input className="form" placeholder="Orientador" maxLength={40} 
                    onChange={handleOrientador2}
                    value={orientador2}
                    /> 

                    <p className="formtext">Participante 1</p>
                    <input className="form" placeholder="Participante 1" maxLength={40} 
                    onChange={handleParticipante1}
                    value={participante1}
                    />  

                    <p className="formtext">Participante 2</p>
                    <input className="form" placeholder="Participante 2" maxLength={40} 
                    onChange={handleParticipante2}
                    value={participante2}
                    />  

                    <p className="formtext">Participante 3</p>
                    <input className="form" placeholder="Participante 3" maxLength={40} 
                    onChange={handleParticipante3}
                    value={participante3}
                    />  

                    <p className="formtext">Participante 4</p>
                    <input className="form" placeholder="Participante 4" maxLength={40} 
                    onChange={handleParticipante4}
                    value={participante4}
                    />  

                    <p className="formtext">Participante 5</p>
                    <input className="form" placeholder="Participante 5" maxLength={40} 
                    onChange={handleParticipante5}
                    value={participante5}
                    />  

                    <p className="formtext">Participante 6</p>
                    <input className="form" placeholder="Participante 6" maxLength={40} 
                    onChange={handleParticipante6}
                    value={participante6}
                    />  

                    <p className="formtext">Participante 7</p>
                    <input className="form" placeholder="Participante 7" maxLength={40} 
                    onChange={handleParticipante7}
                    value={participante7}
                    />  

                </div>
                
                <div className="columner">
                    
                <p className="marger"> Informações de Display </p>

                <p className="formtext">Breve descrição do projeto</p>
                <textarea placeholder="Descrição" rows={7} maxlength="200" className="formDescription" 
                onChange={handleDescription}
                value={description}
                />

                <p className="formtext">Foto do projeto (deve ser em PNG ou JPG)</p>
                <input type="file" id="file" className="inputfile" onChange={(event) => {handleImage(event)}} accept="image/x-png, image/jpeg"/>

                <div className="uploadImage">
                    
                    <label for="file" className="inputbutton">
                        <img src={image} className={classImage} alt="Upload Icon"/>
                    </label>
                </div>

                </div>

                </div>

                <p className="recadao">{recado}</p>

                <Link className="havecount">
                    <p >Alguma dúvida?</p>
                </Link>

                

                <div className="hero-btns">
                    
                    <Button_cadastro className='btns' buttonStyle='btn--primary22'
                    buttonSize='btn--outline' onClick={submit}>
                        Cadastrar 
                    </Button_cadastro>
                </div>
                {idpopup()}
            </animated.div>
        </div>
    )
}

export default Intro

