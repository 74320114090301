import React from 'react'
import './Info.css'
import Youtube from './youtubeVideo.js'

export default function Information() {
    return (
        <div className="informacoes" id="Informacoes"> 
            <h1 className="info_title">Uma plataforma simples de ser utilizada</h1>
            <p className="info_info">Uma plataforma feita para que você aprenda a utilizar em menos de 5 minutos. Para saber mais sobre como utilizar a plataforma e o porquê de sua existência leia ou veja o vídeo abaixo</p>
            <Youtube />
            <h1 className="info_title">Como cadastrar o seu projeto</h1>
            <p className="info_info2">Para cadastrar um projeto, se estiver no computador, basta clicar no botão superior direito, caso esteja no celular, basta clicar no botão superior direito
            e depois em cadastrar projeto. Depois que entrar na aba, basta preencher os dados de acordo com seu projeto. IMPORTANTE: Onde são pedidos links é importante que você coloque APENAS
            o link. Isso para que o link funcione de forma automática. IMPORTANTE 2: Para editar seu projeto posteriormente é importante salvar o ID que é disponibilizado ao fim do cadastro. Depois
            que seu cadastro for finalizado, seu projeto será revisado pela nossa equipe (Para garantir que não há nada de inapropriado) e então será colocado no site.</p>
            <h1 className="info_title">Como participar de um projeto</h1>
            <p className="info_info2">Para participar de um projeto basta clicar em um projeto de sua preferência, e então clicar no botão "Participar". Se o projeto não tiver esse botão
            significa que ele não está aceitando novos membros. Ao clicar no botão, você será redirecionado para um formulário do projeto, que você pode utilizar para inscrever-se para participar</p>
            <h1 className="info_title">Como procurar mais projetos</h1>
            <p className="info_info2">Para encontrar mais projetos no site, caso você esteja no celular, clique no botão superior direito e depois em projetos. Caso esteja no computador, basta clicar em 
            "Projetos". Lá aparecerá uma aba com todos os projetos já cadastrados e aprovados no site. Para saber mais sobre um projeto, basta clicar nele. Para encontrar mais projetos, basta clicar em
            carregar mais, e se quiser voltar, basta clicar no botão voltar.</p>
            <h1 className="info_title">Como cadastrar uma ideia</h1>
            <p className="info_info2">É exatamente igual ao cadastro de um projeto, com pouquíssimas diferenças, sendo elas que um aluno pode cadastrar uma ideia também, que 
            as fases da ideia são: "Montando equipe" quando a ideia ainda está buscando membros para iniciar a fase de "Planejamento" que é quando a execução já está
            sendo planejada. A ideia também pode estar em "Execução", e portanto, deve-se selecionar esta categoria.</p>


            <h1 className="info_title">Como faço para entrar em contato?</h1>
            <p className="info_info2">Você pode enviar um e-mail para projetointegracefetrj@gmail.com</p>
        </div>
    )
}
